@import "../../mixins.scss";
@import "../../sass/constants.scss";

.widgetBoxForm {
  @include f-c-(20px);
  width: 100%;
  justify-content: center;
  .heading{
    @include f-c-(10px);
    align-items: flex-start;
    align-self: stretch;
    .ogTitle{
      font: 700 normal 27px/110% $font-acumin;
      @include font-acumin-sb;
      color: $blueVioletColor;
      @include nav-bp {
        font-size: 32px;
      }
    }
    .ogSubtitle {
      font: 500 normal 10px/150% $font-1;
      color: $darkNeutralPurpleColor;
      @include lg {
        font-size: 12px;
      }
    }
  }

  .form {
    @include f-c-(20px);
    @include lg {
      @include f-c-(25px);
    }
  }
  & .spinnerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tooSmall {
    width: 100%;
    color: $neutralPurple900;
    font: 400 normal 12px/150% $font-1;
    font-style: italic;
    @include lg {
      font-size: 14px;
    }
    .applyLink{
      color: $blueVioletColor;
      font-weight: 600;
      font-style: normal;
    }
  }
  .checkboxClass {
    @include f-r-(10px);
    font-size: 12px;
    align-items: flex-start;
    font-family: $font-1;
    a {
      display: inline;
      font-size: 12px;
      color: $blueVioletColor;
    }
  }
  .submit {
    width: 100%;
    max-width: none;
    max-height: 49px;
    svg{
      width: 15px;
      height: 15px;
      flex-shrink: 0;
      * {
        fill: $white;
      }
    }
  }
}
.spin {
  color: $white;
  height: 22px;
  animation: spin 0.8s infinite linear;
  margin: auto;
  @media screen and (max-width: 990px) {
    width: 20px;
    height: 20px;
  }
}
@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.widgetBox{
  @include f-c-(30px);
  box-sizing: border-box;
  justify-content: center;
  max-width: 500px;
  width: 100%;
  @include nav-bp {
    gap: 50px;
  }
  .content {
    @include fc-cc;
    gap: 20px;
    width: 100%;
    @include nav-bp {
      gap: 30px;
    }
    .icon{
      width: 45px;
      height: 45px;
      margin-top: 10px;
      @include md {
        width: 64px;
        height: 64px;
        margin-top: 20px;
      }
      svg{
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        fill: url(#purpleGradient) $bbPrimaryPurpleColor;
      }
      #svgGradient {
        width:0;
        height:0;
        position:absolute;
      }
    }
    .title {
      font: 700 normal 32px/110% $font-acumin;
      @include font-acumin-sb;
      color: $blueVioletColor;
      text-transform: unset;
      text-align: center;
      @include nav-bp {
        font-size: 50px;
      }
    }
    .subtitle {
      font: 500 normal 14px/150% $font-1;
      color: $darkNeutralPurpleColor;
      text-align: center;
      @include lg {
        font-size: 16px;
      }  
    }
    .homeLink {
      text-decoration: none;
      width: 100%;
      .button2{
        width: 100%;
      }
    }
  }
}
