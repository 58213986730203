@import "../../../../mixins.scss";

.q1 {
  @include xs {
    width: 100%;
    padding: 15px;
    max-width: 400px;
  }
  @include sm {
    padding: 30px;
  }
  @include bp {
    padding: 40px 50px 60px 50px;
    max-width: 510px;
    width: 100%;
  }
  box-sizing: border-box;
  height: 602px;
  background: #ffffff;
  border-radius: 8px;
  .answers {
    @include f-c-(19px);
    @include fs-fs;
    .answer {
      @include f-r-(9.5px);
      @include c-fs;
      input {
        height: 17px;
        width: 17px;
        border: 1px solid #4d3b7a;
      }
      label {
        font: 500 14px/21px "Poppins", sans-serif;
        color: #2a2042;
      }
    }
  }
}
