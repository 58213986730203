@import "../../sass/constants.scss";
@import "../../mixins.scss";

.select-search__menu-list::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  .select-search__menu-list::-webkit-scrollbar-track {
    background: $medium;
  }
  .select-search__menu-list::-webkit-scrollbar-thumb {
    background: rgba($color: $white, $alpha: 0.4);
    border-radius: 4px;
  }
  .select-search__menu-list::-webkit-scrollbar-thumb:hover {
    background: rgba($color: $white, $alpha: 0.3);
  }
  
  .select-search__control--is-focused {
    .select-search__value-container {
      .select-search__placeholder > div {
        color: #352C64;
        opacity: 0.4;
      }
    }
  }
  .select-search__input-container {
    font-size: 12px;
    @include md {
      font-size: 14px;
    }
  }