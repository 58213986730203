@import "../../../../mixins.scss";

.form {
  .outerText {
    color: #f7c948;
    margin-bottom: 30px;
    .sub {
      font: 500 21px/32px "Poppins", sans-serif;
      text-transform: capitalize;
    }
    .main {
      font: 700 27px/32px "Poppins", sans-serif;
      letter-spacing: 0.05em;
    }
  }
  .box {
    @include xs {
      width: 100%;
      padding: 15px;
      max-width: 400px;
      height: 100%;
    }
    @include sm {
      padding: 30px;
    }
    @include bp {
      padding: 40px 50px;
      max-width: 510px;
      width: 100%;
    }
    box-sizing: border-box;
    height: 619px;
    background: #ffffff;
    border-radius: 8px;
    .title {
      font: 700 18px/27px "Poppins", sans-serif;
      letter-spacing: 0.05em;
      color: #594aa5;
      margin-bottom: 20px;
    }
    .body {
      width: 100%;
    }
    .submit {
      @include xs {
        width: 100%;
      }
      @include bp {
        width: 410px;
      }
      @include f-cc;
      height: 50px;
      background: #594aa5;
      box-shadow: 0px 7px 15px #503ec840;
      border-radius: 39px;
      font: 700 13px/20px "Poppins", sans-serif;
      text-transform: uppercase;
      color: #ffffff;
      border: none;
      cursor: pointer;
    }
  }
  .outerButton {
    .divider {
      border-top: 2px solid #8675b8;
      margin: 50px 0px 25px 0px;
    }
    .backButton {
      font: 900 14px/21px "Poppins", sans-serif;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: #fe936f;
      mix-blend-mode: normal;
      border: none;
      background: transparent;
    }
  }
}

.feedback {
  @include f-c-(50px);
  @include c-c;
  height: 100%;
  @include xs {
    min-height: 300px;
  }
  @include sm {
    min-height: 500px;
  }
  @include lg {
    min-height: 550px;
  }
  .body {
    @include f-c-(25px);
    @include c-fs;
    text-align: center;
    .title {
      font: 700 27px/32px "Poppins", sans-serif;
      letter-spacing: 0.05em;
      color: #594aa5;
      margin-bottom: 0px;
    }
    .text {
      font: 500 18px/27px "Poppins", sans-serif;
      text-align: center;
      color: #1c162c;
    }
  }
}

.formButton {
  @include xs {
    width: 100%;
  }
  @include bp {
    width: 410px;
  }
  @include f-r-(10px);
  @include c-c;
  height: 50px;
  background: #594aa5;
  box-shadow: 0px 7px 15px #503ec840;
  border-radius: 39px;
  font: 700 13px/20px "Poppins", sans-serif;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  cursor: pointer;
  .childStyles {
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
      width: 20px;
    }
  }
}
