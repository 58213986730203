@import "../../../../mixins.scss";

.q3 {
  box-sizing: border-box;
  height: 450px;
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  @include xs {
    padding: 15px;
  }
  @include sm {
    padding: 30px;
  }
  @include bp {
    padding: 40px 50px 60px 50px;
  }
  .answer {
    .links{
      @include f-c-(23px);
    }
    @include f-c-(50px);
    @include fs-fs;
    width: 100%;
    .a1,
    .a2,
    .a3 {
      @include f-r-(20px);
      @include c-fs;
      font: 900 14px/21px "Poppins", sans-serif;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      text-decoration: none;
      div:hover {
        text-decoration: underline;
      }
    }
    .a1 {
      color: #5c4792;
      svg {
        min-width: 15px;
        path,
        rect {
          fill: #5c4792 ;
        }
      }
    }
    .a2 {
      color: #4d3b7a;
      svg {
        min-width: 15px;
        path,
        rect {
          fill: #4d3b7a ;
        }
      }
    }
    .a3 {
      font: 700 14px/21px 'Poppins', 'sans-serif';
      line-height: normal;
      border: none;
      display: flex;
      padding: 10px 25px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 2000px;
      background: $bbPrimaryPurpleColor;
      color: #F4F3FA;
      svg {
        path,
        rect {
          fill: #F4F3FA ;
        }
      }
      @include sm {
        padding: 14.5px 50px;
      }
    }
  }
}
.rCol{
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  @include xs {
    width: 100%;
    max-width: 400px;
  }
  @include bp {
    max-width: 510px;
    width: 100%;
  }
}
.outerButton {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  .divider {
    border: 1px solid #8675B8;
    box-sizing: border-box;
    width: 100%;
  }
  .backButton {
    font: 900 14px/21px 'Poppins', 'sans-serif';
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #FE936F;
    mix-blend-mode: normal;
    border: none;
    background: transparent;
  }
}
.title {
  color: #F7C948;
  width: 100%;
  .sub {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    text-transform: capitalize;
    width: 100%;
  }
  .main {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 32px;
    letter-spacing: 0.05em;
    width: 100%;
  }
}
