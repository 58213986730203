@import "../../mixins.scss";

.fixed-button-shell {
  @include xs {
    right: 20px;
  }
  @include md {
    right: 30px;
  }
  @include lg {
    right: 50px;
  }
  height: 58px;
  width: auto;
  border-radius: 5px;
  background-color: $blueViolet500;
  position: fixed;
  box-sizing: border-box;
  padding: 20px 40px;
  z-index: 6;
  .fb-container-shell {
    @include h-w;
    @include f-cc;
    box-sizing: border-box;
    background-color: transparent;
    .fb-text-shell {
      color: $white;
      font: 600 18px/18px $font-1;
    }
  }
}
.shell.snap {
  @include snapScrollContainer;
}