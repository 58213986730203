@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./mixins.scss";
@import url("https://use.typekit.net/bmh3tux.css");

html,
body,
#root {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  z-index: -1000;
}
html{
  background-color: $backgroundBlack;
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  font-family: $font-1;
  margin: 0;
  
}

button {
  background-color: transparent;
}

* {
  box-sizing: border-box;
}

.form-group input:-webkit-autofill {
  background-color: $white !important;
  color: $bbPrimaryColor !important;
  box-shadow: 0 0 0 40px $white inset;
  -webkit-text-fill-color: $bbPrimaryColor !important;
  -webkit-box-shadow: 0 0 0 40px $white inset;
}

.btnLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  svg path {
    fill: $bbPrimaryColor;
  }
}

.textBold {
  font-weight: 700;
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}

.spin {
  animation: spin 0.8s infinite linear;
  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
}