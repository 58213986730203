@import "../../../../mixins.scss";

.wrappedWidget {
  @include xs {
    width: 100%;
  }
  @include bp {
    width: 470px;
  }
  .title {
    color: #F7C948;
    margin-bottom: 20px;
    .sub {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 32px;
      text-transform: capitalize;
    }
    .main {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 27px;
      line-height: 32px;
      letter-spacing: 0.05em;
    }
  }
  .text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #DDDBF1;
    margin-bottom: 20px;
  }
  .outerButton {
    .divider {
      border: 1px solid #8675B8;
      margin: 50px 0px 25px 0px;
    }
    .backButton {
      font: 900 14px/21px 'Poppins', 'sans-serif';
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: #FE936F;
      mix-blend-mode: normal;
      border: none;
      background: transparent;
    }
  }
  .selfServiceButton {
    font: 700 14px/21px 'Poppins', 'sans-serif';
    line-height: normal;
    text-transform: uppercase;
    border: none;
    display: flex;
    min-width: 200px;
    padding: 10px 25px;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 2000px;
    background: #F4F3FA;
    color: $bbPrimaryPurpleColor;
    svg {
      path,
      rect {
        fill: $bbPrimaryPurpleColor;
      }
    }
    @include sm {
      padding: 14.5px 50px;
    }
  }
}