@import "../../mixins.scss";

.input {
  @include f-c-(5px);
  @include fs-fs;
  .input-label {
    font: normal 1em/22px $font-b, $font-c;
  }
  .input-input {
    box-sizing: border-box;
    height: 47px;
    width: 100%;
    border: 1px solid rgb(165,165,165);
    border-radius: 4px;
    padding: 6px 16px;
    &::placeholder {
      font: normal 1.2em/19px $font-a, $font-c;
    }
  }
}