@import "../../../../mixins.scss";

.inputHolder {
  @include f-c;
  @include fs-fs;
  width: 100%;
  margin-bottom: 25px;
  .label {
    font: 500 14px/21px "Poppins", sans-serif;
    color: #433369;
  }
  .input {
    font: 500 21px/32px "Poppins", sans-serif;
    color: #433369;
    padding-left: 0px;
    padding-right: 0px;
    border: none;
    border-bottom: 1px solid #4b5d76;
    width: 100%;
    outline: none;
    &::placeholder {
      color: #c2b9da;
    }
  }
}
