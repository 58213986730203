@import "../../mixins.scss";

.majorManagers {
  box-sizing: border-box;
  .box {
    @include xs {
      width: 100%;
      padding: 0px 5px;
    }
    @include bp {
      padding: 40px 50px 60px 50px;
      width: 510px;
    }
    height: 602px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 8px;
    .card {
      @include f-c;
      @include fs-sb;
      height: 100%;
      .top {
        width: 100%;
        .subtitle {
          font: 500 18px/27px "Poppins", "sans-serif";
          color: #382b58;
        }
        .title {
          font: 700 27px/32px "Poppins", "sans-serif";
          letter-spacing: 0.05em;
          color: #382b58;
        }
        .question {
          font: 700 21px/32px "Poppins", "sans-serif";
          letter-spacing: 0.05em;
          color: #594aa5;
          margin-top: 50px;
          margin-bottom: 25px;
        }
      }
      .bottom {
        width: 100%;
        .backButton {
          font: 900 14px/21px "Poppins", "sans-serif";
          letter-spacing: 0.12em;
          text-transform: uppercase;
          color: #382b58;
          mix-blend-mode: normal;
        }
      }
      .divider {
        border-top: 2px solid #8675b8;
      }
    }
  }
}
