@import "../../mixins.scss";

.landing {
  .video {
    width: 100vw;
    height: calc(100vh - $nav-height-m);
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: -1;
    @include nav-bp {
      height: calc(100vh - $nav-height);
    }
  }
  .landing_container {
    max-width: $max-width;
    z-index: 10;
    width: 100%;
    padding: 30px 25px;
    @include f-c-(30px);
    align-items: flex-start;
    @include md {
      align-items: center;
    }
    @include nav-bp {
      padding: 120px 120px 90px 120px;
      @include f-c-(45px);
    }
    .buttonLink {
      text-decoration: none;
    }
    .mainButton{
      max-width: unset;
      @include nav-bp {
        max-width: 250px;
      }
    }
    .title {
      @include h2;
      color: $white;
      background: unset;
      -webkit-text-fill-color: unset;
      text-align: center;
      margin: 0;
      width: 100%;
      .title2 {
        color: $purpleAccent;
        background: unset;
        -webkit-text-fill-color: unset;
      }
      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
    .lt_subtitle {
      @include f-c-(10px);
      align-items: flex-start;
      color: $snuff-2;
      @include lg-xl {
        @include f-c-(25px);
      }
      .subtitle_heading {
        @include h1-caption;
        color: $lightNeutralPurpleColor;
      }
      p {
        color: $lightNeutralPurpleColor;
        font: 500 normal 16px/150% $font-1;
        align-self: stretch;
        @media screen and (max-width: $md) {
          font-size: 18px;
        }
      }
      ul {
        @include f-c-(5px);
        padding-inline-start: 20px;
        margin: 0;
        @include lg-xl {
          @include f-c-(10px);
        }
      }
    }
    .categories {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      @include md {
        align-items: center;
      }
      @include lg-xl {
        gap: 30px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .category {
        display: flex;
        padding: 15px 30px 15px 15px;
        align-items: center;
        gap: 20px;
        @include md {
          min-width: 315px;
        }
        @include lg-xl {
          min-width: unset;
        }
        svg{
          fill: $white;
          width: 32px;
          height: 32px;
        }
        p {
          color: $lightNeutralPurpleColor;
          text-align: left;
          font: 700 normal 16px/150% $font-1;
          text-transform: uppercase;
          white-space: nowrap;
          @include nav-bp {
            font-size: 18px;
            text-align: center;
          }
        }
      }
    }
    .label_widget {
      @include f-c-(20px);
      box-sizing: border-box;
      max-width: 500px;
      min-width: unset;
      width: 100%;
      background-color: $white;
      border-radius: 15px;
      padding: 20px 25px;
      @include lg {
        min-width: 398px;
        max-width: 510px;
        padding: 40px 50px;
      }
    }
  }
}
