@import "../../mixins.scss";

.input4 {
  @include f-c-(5px);
  @include fs-fs;
  width: 100%;
  &.boxes {
    @include f-c-(15px);
  }
  &.largeBoxes {
    @include f-c-(30px);
  }
  &.radio{
    @include f-c-(10px);
  }
  .labelContainer{
    @include f-r-(10px);
    align-items: flex-start;
    align-self: stretch;
    .optional {
      font: normal 500 10px/150% $font-1;
      color: $neutralPurple600;
      @include nav-bp {
        font-size: 12px;
      }
    }
  }
  .title {
    @include h5-caption;
    font-weight: 500;
    color: $black;
  }
  .captionContainer {
    width: 100%;
    @include f-c-(5px);
    .caption{
      align-self: stretch;
      color: $blueViolet700;
      font: normal 500 10px/normal $font-1;
      margin: 0;
      @include nav-bp {
        font-size: 12px;
      }
    }
  }
  .input {
    box-sizing: border-box;
    height: 25px;
    border: 0px;
    border-bottom: 1px solid $blueViolet400;
    width: 100%;
    outline: none;
    color: $blueVioletColor;
    font: normal 700 12px/142% $font-1;
    padding-bottom: 5px;
    @include nav-bp {
      font-size: 14px;
    }
    &::placeholder { 
      font: normal 700 12px/142% $font-1;
      color: $blueVioletColor;
      opacity: 0.4;
      @include nav-bp {
        font-size: 14px;
      }
    }
    &:focus {
      border-bottom: 2px solid $blueVioletColor;
    }
  }
  .checkList {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: $black;
    gap: 10px 15px;
    width: 100%;
    flex-wrap: wrap;
    .clOption {
      @include f-r-(10px);
      align-items: center;
      justify-content: center;
      input[type="radio"] {
        margin: 0 0 3px 0;
        width: 18px;
        height: 18px;
        accent-color: $bbPrimaryPurpleColor;
        flex-shrink: 0;
      }
      .label {
        @include h5-caption;
        color: $black;
      }
      &.full{
        width: 100%;
      }
    }
  }
  .boxList {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    @include md {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &.full{
      display: flex;
      flex-direction: column;
      @include md {
        flex-direction: row;
      }
    }
    .clOption {
      @include f-r-(0.1em);
      width: 100%;
      @include c-fs;
      align-items: center;
      justify-content: center;
      input[type="radio"]:not(:checked), 
      input[type="radio"]:checked {
        display: none;
      }
      input[type="radio"] + label {
        font: normal 700 12px/142% $font-1;
        @include fc-cc;
        text-transform: uppercase;
        width: 100%;
        cursor: pointer;
        border: 1.5px solid $blueVioletColor;
        color: $blueVioletColor;
        padding: 4.5px 5px;
        gap: 10px;
        border-radius: 8px;
        opacity: 0.25;
        @include md {
          font-size: 14px;
          padding: 4.5px 15px;
        }
        &.md{
          padding: 9.5px 9.5px;
          @include md {
            padding: 9.5px 20px;
          }
        }
      }
      input[type="radio"]:indeterminate + label {
        opacity: 1;
      }
      input[type="radio"]:checked + label {
        opacity: 1;
      }
    }
  }
  .boxLabel {
    font: 700 24px/110% $font-acumin;
    @include font-acumin-sb;
    color: $blueVioletColor;
    text-align: center;
    width: 100%;
    @include nav-bp {
      font-size: 27px;
    }
  }
  .largeBoxList {
    @include f-r;
    gap: 10px;
    flex-wrap: wrap;
    color: #2a2042;
    .clOption {
      @include f-r-(0.1em);
      @include c-fs;
      align-items: center;
      justify-content: center;
      width: 100%;
      input[type="radio"]:not(:checked), 
      input[type="radio"]:checked {
        display: none;
      }
      input[type="radio"] + label {
        font: normal 700 12px/142% $font-1;
        text-transform: uppercase;
        cursor: pointer;
        border: 1.5px solid $blueVioletColor;
        color: $blueVioletColor;
        border-radius: 8px;
        opacity: 0.25;
        @include f-c-(5px);
        @include fc-cc;
        padding: 14.5px 20px;
        width: 100%;
        text-align: center;
        box-sizing: border-box;
        min-height: 75px;
        @include nav-bp {
          font-size: 14px;
        }
        .description {
          color: $black;
          font-size: 10px;
          font-weight: 400;
          line-height: 150%;
          text-transform: none;
          @include nav-bp {
            font-size: 12px;
          }
        }
      }
      input[type="radio"]:indeterminate + label {
        opacity: 1;
      }
      input[type="radio"]:checked + label {
        opacity: 1;
      }
    }
  }
}
