@import "../../../../mixins.scss";

.card {
  @include f-c;
  @include fs-sb;
  height: 100%;
  .top {
    width: 100%;
    .subtitle {
      font: 500 18px/27px 'Poppins', 'sans-serif';
      color: #382B58;
    }
    .title {
      font: 700 27px/32px 'Poppins', 'sans-serif';
      letter-spacing: 0.05em;
      color: #382B58;
    }
    .question {
      font: 700 21px/32px 'Poppins', 'sans-serif';
      letter-spacing: 0.05em;
      color: #594AA5;
      margin-top: 50px;
      margin-bottom: 25px;
    }
  }
  .bottom {
    width: 100%;
    .backButton {
      font: 900 14px/21px 'Poppins', 'sans-serif';
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: #382B58;
      mix-blend-mode: normal;
      border: none;
      background: transparent;
    }
  }
  .divider {
    border-top: 2px solid #8675B8;
  }
}