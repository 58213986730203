@import "../../mixins.scss";

.container {
  position: relative;
}

.container input {
  padding-right: 60px;
}

.iconDiv {
  svg {
    fill: $tooltipFontColor;
  }
  .icon {
    position: absolute;
    cursor: pointer;
    font-size: 22px;
    transform: translateY(10%);
    right: 25px;
    width: 24px;
    height: 24px;
  }
}

.formContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  &.inputWithIcon input {
    padding-left: 35px;
  }
}

.formContainer label {
  color: $darkPurpleColor;
  font: 500 12px/142% $font-1;
  @include md{
    font-size: 14px;
  }
}

.boxInput {
  color: $bbPrimaryPurpleColor;
  font: 700 12px/142% $font-1;
  border: 0;
  border-bottom: 1px solid $black;
  height: 30px;
  outline: none;
  @include md{
    font-size: 14px;
  }
}

.errorClass {
  color: $error;
  font-size: 12px;
  line-height: 15px;
  padding-top: 10px;
  @include md{
    font-size: 14px;
  }
}

.containerClass {
  box-shadow: none;
  outline: none;
  .dropdownClass {
    color: $black;
    box-shadow: 0px 5px 8px -1px rgba($color: $black, $alpha: 0.2);
    width: 353px;
    border: 1px solid $light;
    background-color: $tooltipBackgroundColor;
    margin: 2px 0 0 0;
    li {
      margin: 0 10px;
      &:hover {
        background-color: $light !important;
      }
    }
    @media screen and (max-width: 767px) {
      width: 530px;
    }
    @media screen and (max-width: 567px) {
      width: calc(100vw - 36px);
    }
  }
  > div:last-child {
    background-color: transparent !important;
    border: none;
    &:hover {
      background-color: transparent !important;
    }
    > div {
      background-color: transparent !important;
      width: 70px;
      & > div > div {
        left: 30px !important;
      }
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.inputClass {
  height: 30px;
  border: 0;
  width: 100%;
  height: auto;
  outline: none;
  font-size: 16px;
  padding-left: 60px !important;
  border-bottom: 1px solid $black;
  font: 700 14px $font-1;
  color: $tooltipFontColor;
}

.password {
  input {
    box-sizing: border-box;
    width: 100%;
    padding: 5px 0px;
    background-color: $white;
    border: none;
    border-bottom: 1px solid $black;
    color: $tooltipFontColor;
    border-radius: 2px;
    outline: none;
    font-family: $font-1;
    padding-right: 60px;
  }
}

.radioInput {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .label {
    margin-left: 10px;
    font-size: 14px;
    font-family: $blueVioletColor;
    margin-bottom: 0px;
  }
  input[type="radio"] {
    height: 16px;
    width: 16px;
    accent-color: $blueVioletColor;
  }
}

.componentIcon {
  position: absolute;
  left: 10px;
  width: 20px;
  height: 20px;
  bottom: 11px;
}
