@import "../../mixins.scss";
.footer{
  height: unset !important;
  margin-top: unset !important;
}

.container {
  @include fc-cc;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  * {
    box-sizing: border-box;
  }
  padding: 0px;
  @include nav-bp {
    padding: 0px 25px;
  }
  @include lg-xl{
    padding: 0px 120px;
  }
  .upperFooter {
    @include fc-cc;
    gap: 60px;
    padding: 20px 20px 40px 20px;
    max-width:$max-width-no-padding;
    width: 100%;
    @include nav-bp{
      padding: 45px 0px;
      @include f-r-(60px);
      align-items: flex-start;
    }
    .navContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      .logoContainer {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-bottom: 20px;
        justify-content: space-between;
        position: relative;
        @include nav-bp {
          padding: 30px 0px;
        }
        .logo {
          position: absolute;
          top: 0;
          left: 0;
          @include nav-bp {
            padding-bottom: 0px;
            position: relative;
          }
          svg {
            height: 25px;
            width: fit-content;
            path {
              fill: $white;
            }
            @include nav-bp {
              height: 35px;
            }
          }
        }
        
        .loginContainer {
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: flex-end;
          width: 100%;
          @include nav-bp {
            flex-direction: row;
            width: unset;
            gap: 0px;
          }
          .ctaContainer{
            display: flex;
            width: 100%;
            gap: 20px;
            .loginBtn{
              width: 100%;
              @include nav-bp {
                width: unset;
              }
            }
          }
        }
      }
      .navLinks{
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        align-items: flex-start;
        gap: 20px;
        justify-content: space-between;
        @include nav-bp{
          padding: 45px 0px;
          gap: 30px;
          flex-direction: row;
        }
      }
      .menuContainer {
        display: grid;
        grid-template-columns: auto auto;
        width: 100%;
        gap: 30px;
          @include sm {
            grid-template-columns: auto auto auto;
            
            gap: 45px;
          }
          @include nav-bp{
            width: unset;
          }
        .menu {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 100%;
          flex-grow: 1;
          a {
            font: 700 normal 27px/90% $font-acumin;
            @include font-acumin-sb;
            text-transform: uppercase;
            color: $purpleAccent;
            text-decoration: none;
            width: 100%;
            @include nav-bp {
              font-size: 24px;
            }
          }
          .subMenu {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 5px;
            @include nav-bp {
              gap: 10px;
            }
            a {
              font: 500 14px/142% $font-a;
              font-weight: 500;
              color: $white;
              @include nav-bp {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    
    .socialContainer {
      @include f-c-(25px);
      width: 100%;
      @include nav-bp{
        width: unset;
        @include f-c-(30px);
      }
      .socialLinks {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        @include nav-bp{
          justify-content: flex-end;
          gap: 30px;
          width: unset;
          @include f-r-(30px);
        }
        a {
          svg{
            height: 24px;
            width: fit-content;
          }
          svg * {
            fill: $white;
          }
        }
      }
      .textSocials{
        @include f-c-(10px);
        align-items: center;
        justify-content: center;
        @include nav-bp{
          justify-content: flex-end;
        }
        .xsTextSocials{
          @include f-c-(5px);
          width: 100%;
          align-items: center;
          @include nav-bp{
            align-items: flex-end;
          }
        }
      }
      .address {
        width: 100%;
        text-align: center;
        font: 500 12px/142% $font-1;
        gap: 10px;
        color: $white;
        @include nav-bp {
          font-size: 14px;
          text-align: right;
        }
      }
      .copyright{
        font: 400 10px/150% $font-1;
        color: $white;
        text-align: right;
        @include nav-bp {
          font-size: 12px;
        }
      }
      .rights {
        color: $white;
        font: 400 10px/150% $font-1;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 5px;
        a {
          color: $white;
        }
        @include nav-bp {
          font-size: 12px;
        }
      }
    }
    @media screen and (max-width: 990px) {
      flex-direction: column;
      align-items: center;
    }
  }
}
