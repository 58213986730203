@import "../../mixins.scss";

.container {
  @include h-w;
  @include f-cc;
  box-sizing: border-box;
  background-color: transparent;
  .text {
    text-transform: uppercase;
    color: #3E3374;
    font: 700 13px/20px $font-b, $font-c;
  }
}