@import "../../mixins.scss";

.snapSection {
  height: calc(100vh - $nav-height-m);
  margin-top: $nav-height-m;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: end;
  position: relative;
  overflow: hidden;
  @include nav-bp {
    margin-top: $nav-height;
    height: calc(100vh - $nav-height);
  }
  .left{
    position: absolute;
    overflow-y: hidden !important;
    z-index: 100;
    bottom: 0;
    left: 0;
    margin: 0 0 -1px 0;
    width: 60px;
    height: 60px;
    svg{
      width: 60px;
      height: 60px;
      fill: $backgroundBlack;
    }
    @include nav-bp {
      width: 120px;
      height: 120px;
      svg{
        width: 120px;
        height: 120px;
      } 
    }
  }
  .right{
    position: absolute;
    overflow-y: hidden !important;
    z-index: 100;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    svg{
      width: 60px;
      height: 60px;
      fill: $backgroundBlack;
    }
    margin:-1px 0 0 0;
    @include nav-bp {
      width: 120px;
      height: 120px;
      svg{
        width: 120px;
        height: 120px;
      } 
    }
  }
}
.snapSection > * {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.light {
  @include whiteBG;
}