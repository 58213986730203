@import "../../mixins.scss";

@mixin buttonStyle {
  font-family: $font-a;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
}

.searchBarDiv {
  display: flex;
  max-width: 800px;
  width: 100%;
  &.paddingTop {
    padding-top: 50px;
  }
  .searchBarInnerBar {
    display: flex;
    width: 100%;
    position: relative;
    
    .select {
      padding: 10px 15px;
      border-radius: 24px;
      outline: none;
      background-color: #8675b8;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: #141020;
      @include buttonStyle();
      border: 0px;
      font-size: 16px;
      line-height: 20px;
      position: absolute;
      :global {
        .MuiSelect-select {
          padding: 1px 15px 0px;
        }
      }
      @media screen and (max-width: 1450px) {
        padding: 12px 15px;
        font-size: 12px;
      }
    }
    .inputStyle {
      width: 90%;
      display: flex;
      margin: auto;
      input {
        padding: 9px 65px;
        background-color: transparent;
        outline: none;
        border: 2px solid #8675b8;
        border-left: none;
        border-right: none;
        color: #352c64;
        @include buttonStyle();
        width: 100%;
        @media screen and (max-width: 1024px) {
          padding: 9px 75px;
        }
        @media screen and (max-width: 767px) {
          padding: 9px 85px;
        }
      }
    }
    @media screen and (max-width: 479px) {
      .select {
        padding: 11px;
      }

      button {
        padding: 11px 10px;
      }
    }
  }
  .rightDiv {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
  }
}

.searchGroup {
  width: 100%;
  box-sizing: border-box;
  @include fc-cc;
  gap: 15px;
  .loginDiv {
    color: $white;
    font: 500 12px/20px $font-1;
    text-align: center;
    a {
      color: $purpleAccent;
      text-decoration-line: underline;
    }
    @include md{
      font-size: 14px;
    }
  }
  .idDivMobile{
    @include fr-cc;
    padding: 9.5px var(--App-Text-xl, 20px);
    color: $blueViolet1000; 
    background-color: $purpleAccent;
    font: 700 12px/142% $font-1;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
    border-radius: 2000px;
    span{
      padding: 0 10px;
      white-space: nowrap;
    }
    svg {
      width: 16px;
      height: 16px;
      fill: $blueViolet1000;
    }
    @include md {
      display: none;
    }
  }
}

.placeHolderIcon, .placeHolderIconInput {
  @include f-r-(10px);
  color: $bbPrimaryColor;
  font: 600 14px $font-1;
  align-items: center;
  svg {
    fill: $bbPrimaryColor;
    width: 15px;
    height: 15px;
  }
  @media screen and (max-width: 989px) {
    font-size: 12px;
  }
}
.placeHolderIconInput {
  color: $blueViolet200;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

:global {
  .textured {
    position: relative;
    & > div {
      position: relative;
    }
  }

  div.textured-bg-img {
    position: absolute;
    @include lg-dn {
      background-position: right 250vh, left 140vh, left 140vh;
    }
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: right 250vh, left 75vh, right 120vh;
    background-blend-mode: normal, normal, soft-light;
    background-size: auto, auto, 100%;
    width: 100%;
    height: 100%;
    background-color: #1a1632;
  }
}

.howItWorks {
  max-width: $max-width;
  padding: 30px 25px;
  width: 100%;
  @include f-c-(45px);
  align-items: center;
  @include nav-bp {
    gap: 60px;
    padding: 90px 120px;
  }
  .steps {
    gap: 30px 50px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    width: 100%;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      gap: 15px;
    }
  }
  
}
.howDifferent{
  width: 100%;
  @include sectionBG;
  .howDifferentSection{
    max-width: $max-width;
    padding: 30px 25px;
    @include f-c-(60px);
    align-items: center;
    @include nav-bp {
      padding: 90px 120px;
      gap: 90px;
    }
    .benefits {
      display: grid;
      grid-template-columns: auto;
      gap: 60px 120px;
      align-self: stretch;
      @include md {
        grid-template-columns: auto auto;
      }
      @media screen and (max-width: 767px) {
        gap: 30px;
      }
    }
  }
}

.faqContainer {
  max-width: $max-width;
  padding: 30px 25px;
  @include f-c-(60px);
  display: flex;
  flex-direction: column;
  @include nav-bp {
    gap: 90px;
    padding: 90px 120px;
  }
}

.emptyDiv {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: $bbPrimaryPurpleColor;
    font-size: 16px;
    text-align: center;
  }
  .spin {
    width: 24px;
    height: 24px;
    color: $bbPrimaryPurpleColor;
    animation: rotating 1s linear infinite;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.spotifySearch {
  @include fr-cc;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  border: 4px solid transparent;
  background-image: linear-gradient(white, white), $gradientAccentLight;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-radius: 2000px;
  & > :global(.select-search--is-disabled) {
    cursor: not-allowed;
    filter: brightness(0.85);
  }
  .idDiv {
    display: none;
    padding: 9.5px var(--App-Text-xl, 20px);
    color: $blueViolet1000; 
    background-color: $purpleAccent;
    font: 700 14px/normal $font-1;
    cursor: pointer;
    text-transform: uppercase;
    width: auto;
    border-radius: 2000px 0px 0px 2000px;
    span{
      padding: 0 10px;
      white-space: nowrap;
    }
    svg {
      width: 16px;
      height: 16px;
      fill: $blueViolet1000;
    }
    @include md {
      @include fr-cc;
    }
  }
}

.spotifySearch > div {
  width: 100%;
}

.spotifySearch > i {
  color: $spotify;
  position: absolute;
  background: radial-gradient(
    $white,
    $white,
    $white,
    $white,
    $white,
    transparent,
    transparent,
    transparent,
    transparent
  );
  border-radius: 50px;
  font-size: 25px;
  transform: translateX(-15px);
}

.selectOption {
  @include f-r-(10px);
  padding: 7px 10px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  @include md {
    padding: 7px 20px;
  }
  .profile {
    height: 30px;
    width: 30px;
    border-radius: 20px;
    flex-shrink: 0;
    overflow: hidden;
    img {
      width: 100%;
    }
    .profileIcon {
      height: auto;
      width: auto;
      padding: 5px;
      box-sizing: border-box;
    }
    @media screen and (max-width: 989px) {
      height: 30px;
      width: 30px;
    }
  }
  p {
    font: 700 12px/normal $font-1;
    margin: 0;
    color: $lightNeutralPurpleColor;
    width: 100%;
    @include md {
      font-size: 14px;
    }
  }
  .btn {
    padding: 4px 10px !important;
    margin: 0;
    width: auto !important;
  }
}

.selectedOption {
  @include f-r-(10px);
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  .profile {
    height: 20px;
    width: 20px;
    border-radius: 20px;
    flex-shrink: 0;
    overflow: hidden;
    img {
      width: 100%;
    }
    .profileIcon {
      height: auto;
      width: auto;
      padding: 5px;
      box-sizing: border-box;
    }
  }
  p {
    font: 700 12px/normal $font-1;
    margin: 0;
    color: $bbPrimaryPurpleColor;
    width: 100%;
    @include md {
      font-size: 14px;
    }
  }
  .btn {
    padding: 4px 10px !important;
    margin: 0;
    width: auto !important;
  }
  .spin {
    width: 20px;
    height: 20px;
    color: $bbPrimaryPurpleColor;
    animation: rotating 1s linear infinite;
  }
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.noOption {
  text-align: center;
  color: $white;
}

.inputs{
    @include f-c;
    align-self: stretch;
    align-items: flex-start;
    .search {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
      gap: 5px;
      .label {
        font: normal 500 14px/142% $font-1;
        color: $neutralPurple600;
      }
      .none {
        display: none;
      }
      .switchDiv{
        @include f-r;
        color: $blueVioletColor;
        text-decoration: underline;
        cursor: pointer;
        text-underline-offset: 1px;
        width: 100%;
        justify-content: flex-end;
        font: normal 500 14px/142% $font-1;
      }
    }
    .search > div {
        width: 100%;
      }
    .errorClass {
      color: $error;
      font-size: 12px;
      line-height: 15px;
      margin-right: 40px;
    }
    .removeBtn{
      box-sizing: border-box;
      border: 2px solid $bbPrimaryPurpleColor;
      background-color: transparent;
      box-shadow: none;
      color: $bbPrimaryPurpleColor;
      font: 700 12px/142% $font-1;
      text-transform: uppercase;
      border-radius: 2000px;
    }
}