@import "../../mixins.scss";

.dropzone {
  border: 2px dashed $bbPrimaryColor;
  flex-shrink: 0;
  border-radius: 7px;
  padding: 15px 0;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  * {
    pointer-events: none;
  }
  span {
    text-align: center;
    color: $dark;
    margin-bottom: -10px;
    margin-top: 10px;
    &.highlight {
      color: $bbPrimaryColor;
      text-decoration: underline;
    }
  }
  .dragText,
  br {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px;
    border: 1.5px dashed $bbPrimaryColor;
  }
}

.icon {
  height: 50px;
  width: auto;
  path {
    fill: rgba($color: $helpTextColorRgb, $alpha: 0.3);
  }
}

.fileInput {
  display: none;
}

.cursorDefault {
  cursor: default;
}

.cursorPointer {
  cursor: pointer;
}

.dropHighlight {
  background-color: rgba($color: $bbPrimaryColor, $alpha: 0.1);
}

.loadingOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: progress;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.5);
  color: $grey;
  i {
    margin-top: 100px;
    margin-left: 4px;
  }
  &.dummy {
    cursor: not-allowed;
  }
}

.linkDropzone{
  display: flex;
  align-items: center;
  align-content: center;
  gap: 5px;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  * {
    pointer-events: none;
  }
  .description {
    @include h6-caption;
    width: unset;
    padding-top: 3px;
  }
}
