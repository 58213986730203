@import "../../mixins.scss";

.contactUsForm {
  @include fc-cc;
  width: 100%;
  gap: 43px;
  .title {
    font: normal 700 24px/133% $font-a, $font-c;
    color: $blueVioletColor;
    align-self: stretch;
  }
  .subtitle {
    font: normal 500 12px/150% $font-a, $font-c;
    color: $darkNeutralPurpleColor;
    align-self: stretch;
  }
  .heading {
    @include f-c-(5px);
    align-self: stretch;
  }
  .inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    align-self: stretch;
  }
  .button2 {
    .homeLink {
      text-decoration: none;
      color: $white;
    }
  }
}

.errIcon svg *, .successIcon svg * {
    fill: $gradientBrightToDarkPurple;
  }
.errIcon, .successIcon {
    padding-top: 15px;
}
.errText, .successText{
    display: flex;
    align-self: stretch;
    flex-direction: column;
    gap: 15px;
    .title {
        text-align: center;
    }
    .subtitle {
        font: normal 17px/150% $font-a, $font-c;
        font-weight: 500;
        color: $darkPurpleColor;
        align-self: stretch;
        text-align: center;
    }
}