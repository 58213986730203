@forward "./sass/constants.scss";
@use "sass:list";

@mixin bp {
  @include xl {@content;}
}
@mixin f-c-bp {
  @include f-c-xl;
}
@mixin f-c-bp-($gap1, $gap2: -1) {
  @include f-c-xl-($gap1,$gap2);
}
@mixin f-r-bp {
  @include f-r-xl;
}
@mixin f-r-bp-($gap1, $gap2: -1) {
  @include f-r-xl-($gap1,$gap2);
}

@mixin flex-($flexes...) {
  @if list.length($flexes) > 0 {
    @if list.length($flexes) >= 1 {
      @include f-xs-(list.nth($flexes,1));
    }
    @if list.length($flexes) >= 2 {
      @include f-sm-(list.nth($flexes,2));
    }
    @if list.length($flexes) >= 3 {
      @include f-md-(list.nth($flexes,3));
    }
    @if list.length($flexes) >= 4 {
      @include f-lg-(list.nth($flexes,4));
    }
    @if list.length($flexes) >= 5 {
      @include f-xl-(list.nth($flexes,5));
    }
    @if list.length($flexes) >= 6 {
      @include f-xxl-(list.nth($flexes,6));
    }
  }
}

@mixin h {height: 100%;}
@mixin w {width: 100%;}

@mixin h-w {
  @include h;
  @include w;
}

@mixin h-w-($hw) {
  height: $hw;
  width: $hw;
}

@mixin f {display: flex;}
@mixin i-f {display: inline-flex;}

@mixin r-g-($gap) {row-gap: $gap;}
@mixin c-g-($gap) {column-gap: $gap;}

@mixin f-r {
  @include f;
  flex-direction: row;
}

@mixin f-r-($gap) {
  @include f-r;
  @include c-g-($gap);
}

@mixin f-c {
  @include f;
  flex-direction: column;
}

@mixin f-c-($gap) {
  @include f-c;
  @include r-g-($gap);
}

@mixin i-f-r {
  @include i-f;
  flex-direction: row;
}

@mixin i-f-r-($gap) {
  @include i-f-r;
  @include c-g-($gap);
}

@mixin i-f-c {
  @include i-f;
  flex-direction: column;
}

@mixin i-f-c-($gap) {
  @include i-f-c;
  @include r-g-($gap);
}

@mixin ai-st {align-items: stretch;}
@mixin ai-fs {align-items: flex-start;}
@mixin ai-s {align-items: start;}
@mixin ai-fe {align-items: flex-end;}
@mixin ai-e {align-items: end;}
@mixin ai-c {align-items: center;}
@mixin ai-b {align-items: baseline;}
@mixin ai-sb {align-items: space-between;}

@mixin jc-fs {justify-content: flex-start;}
@mixin jc-fe {justify-content: flex-end;}
@mixin jc-c {justify-content: center;}
@mixin jc-s {justify-content: start;}
@mixin jc-e {justify-content: end;}
@mixin jc-l {justify-content: left;}
@mixin jc-r {justify-content: right;}
@mixin jc-sb {justify-content: space-between;}
@mixin jc-sa {justify-content: space-around;}
@mixin jc-se {justify-content: space-evenly;}

@mixin f-cc {
  @include f;
  @include jc-c;
  @include ai-c;
}

@mixin fr-cc {
  @include f-r;
  @include jc-c;
  @include ai-c;
}

@mixin fc-cc {
  @include f-c;
  @include jc-c;
  @include ai-c;
}

@mixin i-f-cc {
  @include i-f;
  @include jc-c;
  @include ai-c;
}

@mixin st-fs {
  @include jc-fs;
  @include ai-st;
}

@mixin st-fe {
  @include jc-fe;
  @include ai-st;
}

@mixin st-s {
  @include jc-s;
  @include ai-st;
}

@mixin st-e {
  @include jc-e;
  @include ai-st;
}

@mixin st-l {
  @include jc-l;
  @include ai-st;
}

@mixin st-r {
  @include jc-r;
  @include ai-st;
}

@mixin st-sb {
  @include jc-sb;
  @include ai-st;
}

@mixin st-sa {
  @include jc-sa;
  @include ai-st;
}

@mixin st-se {
  @include jc-se;
  @include ai-st;
}

@mixin fs-fs {
  @include jc-fs;
  @include ai-fs;
}

@mixin fs-fe {
  @include jc-fe;
  @include ai-fs;
}

@mixin fs-s {
  @include jc-s;
  @include ai-fs;
}

@mixin fs-e {
  @include jc-e;
  @include ai-fs;
}

@mixin fs-l {
  @include jc-l;
  @include ai-fs;
}

@mixin fs-r {
  @include jc-r;
  @include ai-fs;
}

@mixin fs-sb {
  @include jc-sb;
  @include ai-fs;
}

@mixin fs-sa {
  @include jc-sa;
  @include ai-fs;
}

@mixin fs-se {
  @include jc-se;
  @include ai-fs;
}

@mixin s-fs {
  @include jc-fs;
  @include ai-s;
}

@mixin s-fe {
  @include jc-fe;
  @include ai-s;
}

@mixin s-s {
  @include jc-s;
  @include ai-s;
}

@mixin s-e {
  @include jc-e;
  @include ai-s;
}

@mixin s-l {
  @include jc-l;
  @include ai-s;
}

@mixin s-r {
  @include jc-r;
  @include ai-s;
}

@mixin s-sb {
  @include jc-sb;
  @include ai-s;
}

@mixin s-sa {
  @include jc-sa;
  @include ai-s;
}

@mixin s-se {
  @include jc-se;
  @include ai-s;
}

@mixin fe-fs {
  @include jc-fs;
  @include ai-fe;
}

@mixin fe-fe {
  @include jc-fe;
  @include ai-fe;
}

@mixin fe-s {
  @include jc-s;
  @include ai-fe;
}

@mixin fe-e {
  @include jc-e;
  @include ai-fe;
}

@mixin fe-l {
  @include jc-l;
  @include ai-fe;
}

@mixin fe-r {
  @include jc-r;
  @include ai-fe;
}

@mixin fe-sb {
  @include jc-sb;
  @include ai-fe;
}

@mixin fe-sa {
  @include jc-sa;
  @include ai-fe;
}

@mixin fe-se {
  @include jc-se;
  @include ai-fe;
}

@mixin e-fs {
  @include jc-fs;
  @include ai-e;
}

@mixin e-fe {
  @include jc-fe;
  @include ai-e;
}

@mixin e-s {
  @include jc-s;
  @include ai-e;
}

@mixin e-e {
  @include jc-e;
  @include ai-e;
}

@mixin e-l {
  @include jc-l;
  @include ai-e;
}

@mixin e-r {
  @include jc-r;
  @include ai-e;
}

@mixin e-sb {
  @include jc-sb;
  @include ai-e;
}

@mixin e-sa {
  @include jc-sa;
  @include ai-e;
}

@mixin e-se {
  @include jc-se;
  @include ai-e;
}

@mixin c-fs {
  @include jc-fs;
  @include ai-c;
}

@mixin c-fe {
  @include jc-fe;
  @include ai-c;
}

@mixin c-s {
  @include jc-s;
  @include ai-c;
}

@mixin c-e {
  @include jc-e;
  @include ai-c;
}

@mixin c-l {
  @include jc-l;
  @include ai-c;
}

@mixin c-r {
  @include jc-r;
  @include ai-c;
}

@mixin c-sb {
  @include jc-sb;
  @include ai-c;
}

@mixin c-sa {
  @include jc-sa;
  @include ai-c;
}

@mixin c-se {
  @include jc-se;
  @include ai-c;
}

@mixin b-fs {
  @include jc-fs;
  @include ai-b;
}

@mixin b-fe {
  @include jc-fe;
  @include ai-b;
}

@mixin b-s {
  @include jc-s;
  @include ai-b;
}

@mixin b-e {
  @include jc-e;
  @include ai-b;
}

@mixin b-l {
  @include jc-l;
  @include ai-b;
}

@mixin b-r {
  @include jc-r;
  @include ai-b;
}

@mixin b-sb {
  @include jc-sb;
  @include ai-b;
}

@mixin b-sa {
  @include jc-sa;
  @include ai-b;
}

@mixin b-se {
  @include jc-se;
  @include ai-b;
}

@mixin c-c {
  @include jc-c;
  @include ai-c;
}

@mixin fs-c {
  @include jc-c;
  @include ai-fs;
}

@mixin sb-fs {
  @include jc-fs;
  @include ai-sb;
}

@mixin st-c {
  @include jc-c;
  @include ai-st;
}

@mixin fe-c {
  @include jc-c;
  @include ai-fe;
}

@mixin f-bp-($flex) {@include bp {flex: $flex;}}
@mixin f-xs-($flex) {@include xs {flex: $flex;}}
@mixin f-sm-($flex) {@include sm {flex: $flex;}}
@mixin f-md-($flex) {@include md {flex: $flex;}}
@mixin f-lg-($flex) {@include lg {flex: $flex;}}
@mixin f-xl-($flex) {@include xl {flex: $flex;}}
@mixin f-xxl-($flex) {@include xxl {flex: $flex;}}

@mixin fg-bp-($flex) {@include bp {flex-grow: $flex;}}
@mixin fg-xs-($flex) {@include xs {flex-grow: $flex;}}
@mixin fg-sm-($flex) {@include sm {flex-grow: $flex;}}
@mixin fg-md-($flex) {@include md {flex-grow: $flex;}}
@mixin fg-lg-($flex) {@include lg {flex-grow: $flex;}}
@mixin fg-xl-($flex) {@include xl {flex-grow: $flex;}}
@mixin fg-xxl-($flex) {@include xxl {flex-grow: $flex;}}

@mixin fs-bp-($flex) {@include bp {flex-shrink: $flex;}}
@mixin fs-xs-($flex) {@include xs {flex-shrink: $flex;}}
@mixin fs-sm-($flex) {@include sm {flex-shrink: $flex;}}
@mixin fs-md-($flex) {@include md {flex-shrink: $flex;}}
@mixin fs-lg-($flex) {@include lg {flex-shrink: $flex;}}
@mixin fs-xl-($flex) {@include xl {flex-shrink: $flex;}}
@mixin fs-xxl-($flex) {@include xxl {flex-shrink: $flex;}}

@mixin fb-bp-($flex) {@include bp {flex-basis: $flex;}}
@mixin fb-xs-($flex) {@include xs {flex-basis: $flex;}}
@mixin fb-sm-($flex) {@include sm {flex-basis: $flex;}}
@mixin fb-md-($flex) {@include md {flex-basis: $flex;}}
@mixin fb-lg-($flex) {@include lg {flex-basis: $flex;}}
@mixin fb-xl-($flex) {@include xl {flex-basis: $flex;}}
@mixin fb-xxl-($flex) {@include xxl {flex-basis: $flex;}}

@mixin g {display: grid;}
@mixin i-g {display: -ms-inline-grid;display: -moz-inline-grid;}

@mixin g-c-($columns) {
  grid-template-columns: $columns;
}

@mixin g-r-($rows) {
  grid-template-rows: $rows;
}

@mixin g-a-($areas) {
  grid-template-areas: $areas;
}

@mixin g-($columns,$rows,$areas) {
  @include g;
  @include g-c-($columns);
  @include g-r-($rows);
  @include g-a-($areas);
}

@mixin i-g-($columns,$rows,$areas) {
  @include i-g;
  @include g-c-($columns);
  @include g-r-($rows);
  @include g-a-($areas);
}

@mixin a-($area) {
  grid-area: $area;
}

@mixin h-f {height: fit-content;}
@mixin h-min {height: min-content;}
@mixin h-max {height: max-content;}
@mixin w-f {width: fit-content;}
@mixin w-min {width: min-content;}
@mixin w-max {width: max-content;}

@mixin h-i {@include h-min;}
@mixin h-a {@include h-max;}
@mixin w-i {@include w-min;}
@mixin w-a {@include w-max;}

@mixin h-w-f {
  @include h-f;
  @include w-f;
}

@mixin h-w-min {
  @include h-i;
  @include w-i;
}

@mixin h-w-max {
  @include h-a;
  @include w-a;
}

@mixin h-w-f-min {
  @include h-f;
  @include w-i;
}

@mixin h-w-min-f {
  @include h-i;
  @include w-f;
}

@mixin h-w-f-max {
  @include h-f;
  @include w-a;
}

@mixin h-w-max-f {
  @include h-a;
  @include w-f;
}

@mixin h-w-min-max {
  @include h-i;
  @include w-a;
}

@mixin h-w-max-min {
  @include h-a;
  @include w-i;
}

@mixin h-w-i {
  @include h-w-min;
}

@mixin h-w-a {
  @include h-w-max;
}

@mixin h-w-f-i {
  @include h-w-f-min;
}

@mixin h-w-i-f {
  @include h-w-min-f;
}

@mixin h-w-f-a {
  @include h-w-f-max;
}

@mixin h-w-a-f {
  @include h-w-max-f;
}

@mixin h-w-i-a {
  @include h-w-min-max;
}

@mixin h-w-a-i {
  @include h-w-max-min;
}

@mixin xs {
  @media (min-width: $xs) {@content;}
}
@mixin xs-sm {
  @media (min-width: $xs-sm) {@content;}
}
@mixin sm {
  @media (min-width: $sm) {@content;}
}
@mixin sm-md {
  @media (min-width: $sm-md) {@content;}
}
@mixin md {
  @media (min-width: $md) {@content;}
}
@mixin md-lg {
  @media (min-width: $md-lg) {@content;}
}
@mixin lg {
  @media (min-width: $lg) {@content;}
}
@mixin lg-xl {
  @media (min-width: $lg-xl) {@content;}
}
@mixin xl {
  @media (min-width: $xl) {@content;}
}
@mixin xl-xxl {
  @media (min-width: $xl-xxl) {@content;}
}
@mixin xxl {
  @media (min-width: $xxl) {@content;}
}
@mixin xxl-xk {
  @media (min-width: $xxl-xk) {@content;}
}
@mixin xk {
  @media (min-width: $xk) {@content;}
}

@mixin xs-($offset) {
  @media (min-width: calc($xs + $offset)) {@content;}
}
@mixin xs-sm-($offset) {
  @media (min-width: calc($xs-sm + $offset)) {@content;}
}
@mixin sm-($offset) {
  @media (min-width: calc($sm + $offset)) {@content;}
}
@mixin sm-md-($offset) {
  @media (min-width: calc($sm-md + $offset)) {@content;}
}
@mixin md-($offset) {
  @media (min-width: calc($md + $offset)) {@content;}
}
@mixin md-lg-($offset) {
  @media (min-width: calc($md-lg + $offset)) {@content;}
}
@mixin lg-($offset) {
  @media (min-width: calc($lg + $offset)) {@content;}
}
@mixin lg-xl-($offset) {
  @media (min-width: calc($lg-xl + $offset)) {@content;}
}
@mixin xl-($offset) {
  @media (min-width: calc($xl + $offset)) {@content;}
}
@mixin xl-xxl-($offset) {
  @media (min-width: calc($xl-xxl + $offset)) {@content;}
}
@mixin xxl-($offset) {
  @media (min-width: calc($xxl + $offset)) {@content;}
}
@mixin xxl-xk-($offset) {
  @media (min-width: calc($xxl-xk + $offset)) {@content;}
}
@mixin xk-($offset) {
  @media (min-width: calc($xk + $offset)) {@content;}
}

@mixin bp-min-($offset) {
  @media (min-width: $offset) {@content;}
}
@mixin bp-max-($offset) {
  @media (max-width: $offset) {@content;}
}
@mixin bp-($offset) {
  @include bp-min-($offset) {@content;}
}

@mixin xs-dn {
  @media (max-width: 576px) {@content;}
}
@mixin sm-dn {
  @media (max-width: 576px) {@content;}
}
@mixin md-dn {
  @media (max-width: 768px) {@content;}
}
@mixin lg-dn {
  @media (max-width: 992px) {@content;}
}
@mixin xl-dn {
  @media (max-width: 1200px) {@content;}
}
@mixin xxl-dn {
  @media (max-width: 1200px) {@content;}
}

@mixin max-xs {
  @media (max-width: 576px) {@content;}
}
@mixin max-sm {
  @media (max-width: 576px) {@content;}
}
@mixin max-md {
  @media (max-width: 768px) {@content;}
}
@mixin max-lg {
  @media (max-width: 992px) {@content;}
}
@mixin max-xl {
  @media (max-width: 1200px) {@content;}
}
@mixin max-xxl {
  @media (max-width: 1200px) {@content;}
}

@mixin nav-bp {
  @media (min-width: $nav-bp) {@content;}
}

@mixin c-xl {
  @media (min-width: $c-xl) {@content;}
}

@mixin xs-n {
  @include xs {
    display: none;
  }
}
@mixin sm-n {
  @include sm {
    display: none;
  }
}
@mixin md-n {
  @include md {
    display: none;
  }
}
@mixin lg-n {
  @include lg {
    display: none;
  }
}
@mixin xl-n {
  @include xl {
    display: none;
  }
}
@mixin xxl-n {
  @include xxl {
    display: none;
  }
}

@mixin n-xs {
  @include xs-dn {
    display: none;
  }
}
@mixin n-sm {
  @include sm-dn {
    display: none;
  }
}
@mixin n-md {
  @include md-dn {
    display: none;
  }
}
@mixin n-lg {
  @include lg-dn {
    display: none;
  }
}
@mixin n-xl {
  @include xl-dn {
    display: none;
  }
}
@mixin n-xxl {
  @include xxl-dn {
    display: none;
  }
}

@mixin xs-b {
  @include xs {
    display: block;
  }
}
@mixin sm-b {
  @include sm {
    display: block;
  }
}
@mixin md-b {
  @include md {
    display: block;
  }
}
@mixin lg-b {
  @include lg {
    display: block;
  }
}
@mixin xl-b {
  @include xl {
    display: block;
  }
}

@mixin xs-f {
  @include xs {
    display: flex;
  }
}
@mixin sm-f {
  @include sm {
    display: flex;
  }
}
@mixin md-f {
  @include md {
    display: flex;
  }
}
@mixin lg-f {
  @include lg {
    display: flex;
  }
}
@mixin xl-f {
  @include xl {
    display: flex;
  }
}

@mixin xs-i {
  @include xs {
    display: inline;
  }
}
@mixin sm-i {
  @include sm {
    display: inline;
  }
}
@mixin md-i {
  @include md {
    display: inline;
  }
}
@mixin lg-i {
  @include lg {
    display: inline;
  }
}
@mixin xl-i {
  @include xl {
    display: inline;
  }
}

@mixin xs-ib {
  @include xs {
    display: inline-block;
  }
}
@mixin sm-ib {
  @include sm {
    display: inline-block;
  }
}
@mixin md-ib {
  @include md {
    display: inline-block;
  }
}
@mixin lg-ib {
  @include lg {
    display: inline-block;
  }
}
@mixin xl-ib {
  @include xl {
    display: inline-block;
  }
}

@mixin f-r-xs {
  @include xs {
    @include f-r;
  }
  @include sm {
    @include f-c;
  }
}
@mixin f-r-sm {
  @include xs {
    @include f-r;
  }
  @include md {
    @include f-c;
  }
}
@mixin f-r-md {
  @include xs {
    @include f-r;
  }
  @include lg {
    @include f-c;
  }
}
@mixin f-r-lg {
  @include xs {
    @include f-r;
  }
  @include xl {
    @include f-c;
  }
}
@mixin f-r-xl {
  @include xs {
    @include f-r;
  }
  @include xxl {
    @include f-c;
  }
}

@mixin f-c-xs {
  @include xs {
    @include f-c;
  }
  @include sm {
    @include f-r;
  }
}
@mixin f-c-sm {
  @include xs {
    @include f-c;
  }
  @include md {
    @include f-r;
  }
}
@mixin f-c-md {
  @include xs {
    @include f-c;
  }
  @include lg {
    @include f-r;
  }
}
@mixin f-c-lg {
  @include xs {
    @include f-c;
  }
  @include xl {
    @include f-r;
  }
}
@mixin f-c-xl {
  @include xs {
    @include f-c;
  }
  @include xxl {
    @include f-r;
  }
}

@mixin f-r-xs-($gap1, $gap2: -1) {
  @include xs {
    @include f-r-($gap1);
  }
  @include sm {
    @if $gap2 == -1 {
      @include f-c-($gap1);
    } @else {
      @include f-c-($gap2);
    }
  }
}
@mixin f-r-sm-($gap1, $gap2: -1) {
  @include xs {
    @include f-r-($gap1);
  }
  @include md {
    @if $gap2 == -1 {
      @include f-c-($gap1);
    } @else {
      @include f-c-($gap2);
    }
  }
}
@mixin f-r-md-($gap1, $gap2: -1) {
  @include xs {
    @include f-r-($gap1);
  }
  @include lg {
    @if $gap2 == -1 {
      @include f-c-($gap1);
    } @else {
      @include f-c-($gap2);
    }
  }
}
@mixin f-r-lg-($gap1, $gap2: -1) {
  @include xs {
    @include f-r-($gap1);
  }
  @include xl {
    @if $gap2 == -1 {
      @include f-c-($gap1);
    } @else {
      @include f-c-($gap2);
    }
  }
}
@mixin f-r-xl-($gap1, $gap2: -1) {
  @include xs {
    @include f-r-($gap1);
  }
  @include xxl {
    @if $gap2 == -1 {
      @include f-c-($gap1);
    } @else {
      @include f-c-($gap2);
    }
  }
}

@mixin f-c-xs-($gap1, $gap2: -1) {
  @include xs {
    @include f-c-($gap1);
  }
  @include sm {
    @if $gap2 == -1 {
      @include f-r-($gap1);
    } @else {
      @include f-r-($gap2);
    }
  }
}
@mixin f-c-sm-($gap1, $gap2: -1) {
  @include xs {
    @include f-c-($gap1);
  }
  @include md {
    @if $gap2 == -1 {
      @include f-r-($gap1);
    } @else {
      @include f-r-($gap2);
    }
  }
}
@mixin f-c-md-($gap1, $gap2: -1) {
  @include xs {
    @include f-c-($gap1);
  }
  @include lg {
    @if $gap2 == -1 {
      @include f-r-($gap1);
    } @else {
      @include f-r-($gap2);
    }
  }
}
@mixin f-c-lg-($gap1, $gap2: -1) {
  @include xs {
    @include f-c-($gap1);
  }
  @include xl {
    @if $gap2 == -1 {
      @include f-r-($gap1);
    } @else {
      @include f-r-($gap2);
    }
  }
}
@mixin f-c-xl-($gap1, $gap2: -1) {
  @include xs {
    @include f-c-($gap1);
  }
  @include xxl {
    @if $gap2 == -1 {
      @include f-r-($gap1);
    } @else {
      @include f-r-($gap2);
    }
  }
}

@mixin font-acumin-b {
  font-family: $font-acumin;
  font-variation-settings: 'wdth' 50, 'slnt' 0;
}

@mixin font-acumin-sb {
  font-family: $font-acumin;
  font-variation-settings: 'wdth' 50, 'slnt' 0;

}

@mixin font-acumin-r {
  font-family: $font-acumin;
  font-variation-settings: 'wdth' 50, 'slnt' 0;
}

// font heading styles

@mixin h1 {
  font: 600 normal 4.6875rem/90% $font-acumin;
  @include font-acumin-sb;
  letter-spacing: .125rem;
  text-transform: uppercase;
  overflow-wrap: break-word;
  width: 100%;
  @include f-r-(15px);
  @include sm {
    font-size: 100px;
  }
  span {
    width: 100%;
    @include md{
      width: unset;
    }
  }
  flex-wrap: wrap;
  @include lg {
    font-size: 7.8125rem;
  }
}
@mixin text-gradient{
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 0.2em;
  margin-bottom: -0.2em;
}

@mixin h1-caption {
  font: 500 normal 1.25rem/133% $font-1;
  color: $white;
  width: 100%;
  @include lg {
    font-size: 1.5rem;
  }
}

@mixin h2 {
  font: 600 normal 3.75rem/90% $font-acumin;
  @include font-acumin-sb;
  text-transform: uppercase;
  overflow-wrap: break-word;
  background: $gradientBrightToDarkPurple;
  @include text-gradient;
  width: 100%;
  @include lg {
    font-size: 6.25rem;
  }
}
@mixin h2-caption {
  font: 500 normal 1.125rem/150% $font-1;
  color: $black;
  width: 100%;
  @include lg {
    font-size: 1.25rem;
  }
}

@mixin h3 {
  font: 600 normal 2.8125rem/110% $font-acumin;
  @include font-acumin-sb;
  overflow-wrap: break-word;
  background: $gradientBrightToDarkPurple;
  @include text-gradient;
  width: 100%;
  @include lg {
    font-size: 4.6875rem;
  }
}

@mixin h3-caption {
  font: 500 normal 1rem/150% $font-1;
  color: $black;
  width: 100%;
  @include lg {
    font-size: 1.125rem;
  }
}

@mixin h4 {
  font: 600 normal 2rem/110% $font-acumin;
  @include font-acumin-sb;
  overflow-wrap: break-word;
  width: 100%;
  @include lg {
    font-size: 3.125rem;
  }
}

@mixin h4-caption {
  font: 500 normal 0.875rem/150% $font-1;
  color: $black;
  width: 100%;
  @include lg {
    font-size: 1rem;
  }
}

@mixin h5 {
  font: 500 normal 1.6875rem/110% $font-acumin;
  @include font-acumin-sb;
  overflow-wrap: break-word;
  width: 100%;
  @include lg {
    font-size: 2rem;
  }
}

@mixin h5-caption {
  font: 500 normal 0.75rem/150% $font-1;
  color: $black;
  width: 100%;
  @include lg {
    font-size: 0.875rem;
  }
}

@mixin h6 {
  font: 500 normal 1.5rem/110% $font-acumin;
  @include font-acumin-sb;
  overflow-wrap: break-word;
  width: 100%;
  @include lg {
    font-size: 1.6875rem;
  }
}

@mixin h6-caption {
  font: 500 normal 0.625rem/150% $font-1;
  color: $black;
  width: 100%;
  @include lg {
    font-size: 0.75rem;
  }
}

@mixin h6-secondary {
  font: 700 normal 1.25rem/133% $font-1;
  text-transform: uppercase;
  color: $bbPrimaryPurpleColor;
  @include lg{
    font-size: 1.5rem;
  }
}

@mixin backgroundTexture {
  @include whiteBG();
  overflow: hidden;
  margin-top: $nav-height-m;
  @include nav-bp {
    margin-top: $nav-height;
  }
}

@mixin whiteBG {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $lightNeutralPurpleColor; 
  background-image: url("./assets/images/whiteBG.webp");
  background-position: center;
}

@mixin sectionBG {
  @include fc-cc();
  width: 100%;
}

@mixin lowercaseTextGradient{
  padding-bottom: 0.2em;
  margin: 0 0 -0.2em 0;
}

@mixin snapScrollContainer{
  position: relative;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  height: 100vh;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}