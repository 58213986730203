@import "../../mixins.scss";

.gloss-box {
  @include f-c;
  @include fs-c;
  @include xs {
    width: 100%;
  }
  @include bp {
    width: 100%;
  }
  .click{
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  .gb-header {
    @include f-r-(22px);
    align-items: center;
    @include jc-sb;
    padding: 30px 0px;
    border-top: 2px solid $blueViolet700;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    .gb-arrow {
      @include ai-fe;
      svg {
        @include h-w-(24px);
        fill: $bbPrimaryPurpleColor;
      }
    }
    .gb-term {
      @include fr-cc;
      gap: 15px;
      font: 700 27px/110% $font-acumin;
      @include font-acumin-sb;
      @include md {
        font-size: 32px;
      }
      &.gb-closed {
        color: $blueViolet600;
      }
      &.gb-open {
        color: $bbPrimaryPurpleColor;
      }
      .icon-div {
        width: 20px;
          height: 20px;
          @include md {
            width: 30px;
            height: 30px;
          }
        svg {
          width: 100%;
          height: 100%;
          fill: $bbPrimaryPurpleColor;
        }
      }
    }
  }
  .gb-definition {
    @include h5-caption;
    color: $black;
    z-index: 1;
    position: relative;
    transition: all 0.3s ease-in-out;
    a {
      color: $bbPrimaryPurpleColor;
    }
  }
}

.linkDiv {
  padding-left: 0px !important;
  p {
    padding-left: 0px;
  }
}
