@import "../../mixins.scss";

.slide{
    display: flex;
    flex-direction: row;
    width: 100vw;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 30px 10px 30px 25px;
    @include nav-bp {
        gap: 45px;
        padding: 90px 45px 90px 120px;
    }
}

.indicator{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0;
    list-style-type: none;
    .bulletText{
        display: none;
    }
    ::marker{
        display: none;
    }
    .bullet {
        width: 8px;
        height: 8px;
        padding: 5px;
        box-shadow:
                0 0 0 3px $backgroundBlack,
                0 0 0 5px $white;
        border-radius: 2000px;
        background-color: $backgroundBlack;
        &.active{
            width: 8px;
            height: 8px;
            padding: 5px;
            box-shadow:
                0 0 0 3px $backgroundBlack,
                0 0 0 5px $purpleAccent;
            border-radius: 2000px;
            background-color: $purpleAccent;
        }
    }
    
}

.container.light{
    @include whiteBG;
    position: relative;
    .bullet {
        box-shadow:
                0 0 0 3px $white,
                0 0 0 5px $bbPrimaryPurpleColor;
        background-color: $white;
        &.active{
            box-shadow:
                0 0 0 3px $white,
                0 0 0 5px $bbPrimaryPurpleColor;
            background-color: $bbPrimaryPurpleColor;
        }
    }
    .left{
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0 0 -1px 0;
        width: 60px;
        height: 60px;
        fill: $backgroundBlack;
        @include nav-bp {
            width: 120px;
            height: 120px;
        }
      }
      .right{
        position: absolute;
        z-index: 100;
        fill: $backgroundBlack;
        top: 0;
        right: 0;
        width: 60px;
        height: 60px;
        margin:-1px 0 0 0;
        @include nav-bp {
            width: 120px;
            height: 120px;
        }
      }
}

