@import "../../mixins.scss";

.glossary {
  @include fc-cc;
  @include backgroundTexture;
  width: 100%;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  h2 {
    @include h2
  }
  .bgImageCtn {
    background-image: radial-gradient(
        122.64% 258.65% at 100% 100%,
        rgba(26, 22, 50, 0.81) 0%,
        rgba(26, 22, 50, 0.45) 100%
      ),
      url("../../assets/images/file-folders-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include fc-cc;
    width: 100%;
    .landingCtn {
      align-items: center;
      display: flex;
      flex-direction: column;
      max-width: $max-width;
      width: 100%;
      padding: 30px 25px;
      gap: 50px;
      @include nav-bp{
        flex-direction: row;
        padding: 90px 120px;
        gap: 100px;
      }
      .headingCtn {
        @include f-c-(30px);
        width: 100%;
        .titleContainer {
          @include h1;
          width: 100%;
          .primary {
            color: $white;
          }
          .secondary {
            color: $purpleAccent;
          }
        }
        .subHeading {
          @include h1-caption;
        }
      }
    }
  }

  .gloss_list {
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    column-gap: 120px;
    max-width: $max-width;
    width: 100%;
    padding: 30px 25px;
    align-content: flex-start;
    @include nav-bp{
      padding: 120px;
      display: grid;
      grid-template-areas: "card card";
      grid-auto-rows: auto;
      align-items: start;
    }
  }
}

.overlayClass {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  border-radius: 12px;
  max-width: 750px;
  width: 95%;
  outline: none;
  padding: 40px 20px 20px 20px;  
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  .icon {
    padding: 10px 20px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    svg {
      width: 14px;
      height: 14px;
      fill: $bbPrimaryPurpleColor;
    }
  }
  .iframeContainer {
    aspect-ratio: 16/9;
    max-width: 100%;
    .iframe {
      width: 100%;
      height: 100%;
    }
  }
}



.noData {
  width: 100%;
  display: flex;
  min-height: 200px;
}
