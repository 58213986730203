@import "../../mixins.scss";

.dropdown {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
    .labelContainer{
        @include f-r-(10px);
        align-items: flex-start;
        align-self: stretch;
        .optional {
          font: normal 500 12px/150% $font-1;
          color: $neutralPurple600;
        }
    }
    label {
      color: $blueViolet700;
      font: normal 500 14px/142% $font-a, $font-c;
      width: 100%;
      margin:0;
      padding:0;
    }
    .captionContainer {
        width: 100%;
        @include f-c-(5px);
        .caption{
          align-self: stretch;
          color: $blueViolet700;
          font: normal 500 10px/normal $font-1;
          margin: 0;
          @include nav-bp {
            font-size: 12px;
          }
        }
      }
    .dropdownContainer {
      display: flex;
      padding: 0px 0px 5px 0px;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      align-self: stretch;
      font: normal 700 14px/142% $font-a, $font-c;
      color: $blueVioletColor;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid $blueViolet700;
      appearance: none;
      background-image: url("../../assets/SVGs/arrow-down.svg");
      background-repeat: no-repeat;
      background-position: right 0.7rem top 50%;
      background-size: 1rem auto;
      &:hover {
        border-bottom-color: rgba($blueViolet700, .8);
      }
      &:focus, &:active {
        border-bottom-color: $blueVioletColor;
        outline: none;
      }
      .dd-value {
        @include f-r;
        @include c-sb;
        .dd-placeholder {
          font: normal 700 14px/142% $font-a, $font-c;
          color: rgb($blueVioletColor, 0.4);
        }
      }
    
    }
  }