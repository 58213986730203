@import "../../mixins.scss";
@import "../../sass/constants.scss";

.widget {
  box-sizing: border-box;
  height: 610px;
  min-height: 500px;
  width: 100%;
  min-width: 398px;
  background-clip: border-box;
  background-color: $white;
  border-radius: 7px;
  color: rgb(27, 27, 27);
  font-size: 16px;
  font-weight: normal;
  justify-content: center;
  line-height: 24px;
  padding: 20px;
  text-align: left;
  overflow: hidden;
  @include xs {
    max-width: 398px;
    min-width: unset;
    height: 650px;
  }
  @include lg {
    min-width: 398px;
    max-width: 470px;
    height: 620px;
  }
  .getFundedContainer {
    overflow: hidden;
    &-normal {
      height: 100%;
      width: 100%;
    }
    &-no-padding {
      height: calc(100% - 20px);
      width: calc(100% - 20px);
    }
  }
  #getFunded {
    overflow: hidden;
    #widgetRenderArea {
      overflow: hidden;
      border: none;
      height: 100%;
      width: 100%;
    }
  }
}
#getFunded > div.theme-bg-dark > div > div > h2 {
  font: 700 28px/33.6px $font-a, $font-c !important;
  letter-spacing: 1.2px !important;
}
